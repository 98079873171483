<template>
  <div class="p-3 liff-reservation-form">
    <b-modal id="modal-save" centered hide-header hide-footer>
      <template #default="{ close }">
        <div class="liff-reservation-modal" :style="colorObject">
          <button class="liff-reservation-modal__close" @click="close()">
            <img src="./images/close.svg" />
          </button>

          <swiper :options="swiperOption">
            <swiper-slide v-for="(i, idx) in modals" :key="idx">
              <div class="badge__detail__hero">
                <div class="badge__detail__hero__img">
                  <img src="https://picsum.photos/800/600" class="img-fluid" />
                </div>                
              </div>
              <div class="py-3 border-bottom px-4">
                <div class="badge__detail__hero__title mt-2"></div>
                <ul>
                  <li v-for="(c, cdx) in i" :key="cdx">{{ c }}</li>
                </ul>
              </div>
              <div class="py-3">
                <SharedButton class="s-btn-bg-primary">選擇</SharedButton>
              </div>
            </swiper-slide>
          </swiper>

          <div class="swiper-button-prev">
          </div>
          <div class="swiper-button-next">
            
          </div>
        </div>
      </template>
    </b-modal>

    <b-card header-tag="header" class="mb-3 liff-reservation-form__card">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
          <button class="btn btn-link btn-sm" v-b-modal.modal-save>儲存</button>
        </div>
      </template>
      <b-form-group
        label="姓名"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
        class="mb-3"
      >
        <b-form-input v-model="form.name" class="text-right" />
      </b-form-group>
      <b-form-group
        label="電話"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
      >
        <b-form-input v-model="form.phone" class="text-right" />
      </b-form-group>
    </b-card>

    <b-card header-tag="header" class="mb-3 liff-reservation-form__card">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約資訊</h6>
          <button class="btn btn-link btn-sm">儲存</button>
        </div>
      </template>
      <b-form-group
        label="項目名稱"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
        class="mb-3"
      >
        <b-form-input v-model="form.serviceName" class="text-right" />
      </b-form-group>
      <b-form-group
        label="服務人員"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
        class="mb-3"
      >
        <b-form-input v-model="form.servicePerson" class="text-right" />
      </b-form-group>
      <b-form-group
        label="預約時間"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
        class="mb-3"
      >
        <b-form-input v-model="form.serviceTime" class="text-right" />
      </b-form-group>
      <b-form-group
        label="服務地點"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        label-for="input-sm"
        class="mb-3"
      >
        <b-form-input v-model="form.serviceLocation" class="text-right" />
      </b-form-group>
    </b-card>

    <div class="mb-3">
      <b-form-group label="備註">
        <b-form-textarea
          v-model="form.note"
          class="liff-reservation-form__textarea"
        />
      </b-form-group>
    </div>

    <div class="d-flex s-space-x-2">
      <SharedButton class="s-btn-outline-primary w-50">上一步</SharedButton>
      <SharedButton class="s-btn-bg-primary w-50">送出</SharedButton>
    </div>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button.vue";
import themeColor from "@/mixins/liff/themeColor";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  mixins: [themeColor],
  components: {
    SharedButton,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      form: {
        name: "林曉娟",
        phone: "0987654321",
        serviceName: "精緻剪髮、歐系染髮、洗髮",
        servicePerson: "Nymphia",
        serviceTime: "2024/01/01 09:00",
        serviceLocation: "台北市中山區中山北路二段36巷36號",
        note: "我幫我媽預約，媽媽姓名王美花",
      },
      modals: [
        [
          "租車公司名稱：台灣租車",
          "車型選擇：轎車、SUV、廂型車",
          "租車費用：每日 NT$1500 起",
          "租車條件：需持有有效駕照",
        ],
        [
          "租車公司名稱：台灣租車",
          "車型選擇：轎車、SUV、廂型車",
          "租車費用：每日 NT$1500 起",
          "租車條件：需持有有效駕照",
        ],
      ],
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.liff-reservation-form {
  &__card {
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    .card-header {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #e5e5ea;
      font-size: 16px;
      font-weight: 500;
      padding: 16px 16px 12px 16px;
    }
  }
  &__textarea {
    border-radius: 10px;
    border: 1px solid #636366;
    color: #2c2c2e;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    padding: 16px 12px;
  }

  input {
    font-size: 14px;
    height: auto;
    line-height: 1.2;
    border-bottom: 1px solid #e5e5ea;
    padding-bottom: 6px;
  }
}

.liff-reservation-modal {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 0 8px;
    background: white;    
    border-radius: 40px;
    position: absolute;
    bottom: 12px;    
    z-index: 1;
    aspect-ratio: 1;
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    

    &::after{
      color: black;
      font-size: 18px;
    }
  
  }

  .swiper-button-prev{
    left: -5px;
  }
  .swiper-button-next{
    right: -5px;
  }
}

::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}
</style>
